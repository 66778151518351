import React, { useState, useRef, useEffect } from 'react';
import { TFunction } from 'i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import * as EmailValidator from 'email-validator';
import qs from 'qs';

import Input, { InputType } from '../primitives/Input/Input';
import Button from '../primitives/Button/Button';
import TextArea from '../primitives/TextArea/TextArea';
import { IconTypes } from '../Icon/Icon';
import { useLocaleContext } from '../../context/Locale';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { LocaleNameSpaceKeys } from '../../localization/translations';

import './style.scss';

const complaintsRecordType = process.env.GATSBY_COMPLAINTS_RECORD_TYPE || '0125o0000004Mn7';
const isProd = complaintsRecordType === '0125o0000004Mn7';
const debugEmail = 'itsalesforceadmins@haventreebank.com';
const productTypeRecordType = process.env.GATSBY_PRODUCT_RECORD_TYPE || '00N4m000000cbEw';
const applicantTypeRecordType = process.env.GATSBY_APPLICANT_RECORD_TYPE || '00N4m000000cbEv';

export enum FormFor {
  mortgageExisting = 'mortgageExisting',
  mortgageNew = 'mortgageNew',
  gic = 'gic',
  privacy = 'privacy',
  default = '',
}

interface OwnProps {
  t: TFunction;
  formFor: FormFor;
}

enum FormDataKey {
  name = 'name',
  email = 'email',
  message = 'message',
  recaptcha = 'reCaptcha',
}

type Props = OwnProps;

const ComplaintsFormForm: React.FC<Props> = ({ t, formFor }) => {
  const { activeLocaleTag } = useLocaleContext();
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const reCaptchaTimerRef = useRef('');

  const initialFormState = {
    name: {
      isInvalid: false,
      validationMessage: '',
      value: '',
    },
    email: {
      isInvalid: false,
      validationMessage: '',
      value: '',
    },
    message: {
      isInvalid: false,
      validationMessage: '',
      value: '',
    },
    reCaptcha: {
      isInvalid: false,
      validationMessage: '',
      value: '',
      ts: '',
    },
  };

  const [formValues, setFormValues] = useState(initialFormState);
  const [areFormValuesInvalid, setAreFormValuesInvalid] = useState(false);

  const [formSubmissionResponse, setFormSubmissionResponse] = useState({
    submitted: false,
    shouldDisplayMessage: false,
  });

  const handleFormChange = (val: any, id: FormDataKey) => {
    setFormValues({
      ...formValues,
      [id]: {
        ...formValues[id],
        value: val,
      },
    });
  };

  const timestamp = () => {
    const response = document.getElementById('g-recaptcha-response');
    // @ts-ignore
    // eslint-disable-next-line eqeqeq
    if (response == null || response.value.trim() == '') {
      reCaptchaTimerRef.current = JSON.stringify(new Date().getTime());
    }
  };

  const handleCaptchaOnChange = (value: any) => {
    setFormValues((prevState) => ({
      ...prevState,
      reCaptcha: {
        ...prevState.reCaptcha,
        isInvalid: false,
        value,
      },
    }));
  };

  useEffect(() => {
    const interval = setInterval(timestamp, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (formSubmissionResponse.shouldDisplayMessage && formSubmissionResponse.submitted) {
      const message = document.getElementById('success-message');

      if (message) {
        message.scrollIntoView({ block: 'end' });
      }
    }
  }, [formSubmissionResponse]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const newFormValues = { ...formValues };
    const formValueFields = Object.keys(newFormValues);
    let firstErrorField = '';

    formValueFields.forEach((element) => {
      const currentField = newFormValues[element as FormDataKey];

      if (!currentField.value) {
        currentField.isInvalid = true;
        if (!firstErrorField) firstErrorField = element;

        currentField.validationMessage = t(
          `contactUs.form.${element}.validationMessage.emptyField`
        );
      } else if (element === 'email' && !EmailValidator.validate(currentField.value)) {
        currentField.isInvalid = true;
        if (!firstErrorField) firstErrorField = element;

        currentField.validationMessage = t(
          'contactUs.form.email.validationMessage.invalidCharacters'
        );
      } else {
        currentField.isInvalid = false;
        currentField.validationMessage = '';
      }
    });

    const newAreFormValuesInvalid = formValueFields.some(
      (field) => (newFormValues as any)[field].isInvalid
    );

    setFormValues(newFormValues);
    setAreFormValuesInvalid(newAreFormValuesInvalid);

    if (!newAreFormValuesInvalid) {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }

      const emailToMapper: any = {
        mortgageExisting: t(`${LocaleNameSpaceKeys.COMMON}:emails.complaintsServicing`),
        mortgageNew: t(`${LocaleNameSpaceKeys.COMMON}:emails.complaintsOriginations`),
        privacy: t(`${LocaleNameSpaceKeys.COMMON}:emails.complaintsPrivacy`),
        gic: t(`${LocaleNameSpaceKeys.COMMON}:emails.complaintsGIC`),
      };

      const additionalFieldsMapper: {
        [key: string]: {
          productType: string;
          applicantType: string | null;
        };
      } = {
        mortgageExisting: {
          productType: 'Mortgage',
          applicantType: 'Existing',
        },
        mortgageNew: {
          productType: 'Mortgage',
          applicantType: 'New',
        },
        privacy: {
          productType: 'Privacy',
          applicantType: null,
        },
        gic: {
          productType: 'Investment',
          applicantType: null,
        },
      };

      const finalObject = {
        debug: isProd ? 0 : 1,
        orgid: process.env.GATSBY_WEB_TO_CASE_SALESFORCE_ORG_ID,
        '00N5o000000Otwa': isProd ? emailToMapper[formFor as FormFor] : debugEmail,
        name: formValues.name.value,
        email: formValues.email.value,
        recordType: complaintsRecordType,
        subject: `${t('complaintsHandling.heading')} - ${t(
          `complaintsHandling.step1.complaintOptions.${formFor}`
        )}`,
        [productTypeRecordType]: additionalFieldsMapper[formFor].productType,
        [applicantTypeRecordType]: additionalFieldsMapper[formFor].applicantType,
        description: formValues.message.value,
        'g-recaptcha-response': formValues.reCaptcha.value,
        captcha_settings: JSON.stringify({
          keyname: process.env.GATSBY_WEB_TO_CASE_SALESFORCE_CAPTCHA_KEY_NAME,
          fallback: 'true',
          orgId: process.env.GATSBY_WEB_TO_CASE_SALESFORCE_ORG_ID,
          ts: reCaptchaTimerRef.current, // inclusion of time means response was validated
        }),
        submit: 'Submit',
        ...(isProd ? {} : { debugEmail }),
      };

      await fetch(process.env.GATSBY_WEB_TO_CASE_SALESFORCE_URL as string, {
        method: 'POST',
        body: qs.stringify(finalObject),
        credentials: 'same-origin',
        mode: 'no-cors',
        // @ts-ignore
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          Accept:
            'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
          Origin: null,
          'Upgrade-Insecure-Requests': 1,
        },
      })
        .then(() => {
          setFormSubmissionResponse({
            submitted: true,
            shouldDisplayMessage: true,
          });

          setFormValues(() => initialFormState);
        })
        .catch((err) => {
          console.error(`Failed: `, err);
          setFormSubmissionResponse({
            submitted: false,
            shouldDisplayMessage: true,
          });
        });

      return true;
    }

    if (firstErrorField) {
      let inputField;

      if (firstErrorField === FormDataKey.recaptcha) {
        inputField = document.querySelector('recaptcha-checkbox-border');
      } else {
        inputField = document.getElementById(firstErrorField);
      }

      if (inputField) {
        (inputField as HTMLElement).focus();
      }
    }

    return false;
  };

  return formSubmissionResponse.shouldDisplayMessage && formSubmissionResponse.submitted ? (
    <div className="ComplaintsForm__generic-gap">
      <p className="ComplaintsForm__success-message" aria-live="polite" id="success-message">
        {t('contactUs.form.submissionValidation.complaintSuccess')}
      </p>
    </div>
  ) : (
    <form onSubmit={handleSubmit} className="ComplaintsForm ComplaintsForm__generic-gap">
      <Input
        id="name"
        isInvalid={formValues.name.isInvalid}
        validationMessage={formValues.name.validationMessage}
        options={{
          type: InputType.TEXT,
          label: t('contactUs.form.name.label'),
          inputProps: {
            name: 'name',
            value: formValues.name.value,
          },
        }}
        onChange={handleFormChange}
        required
      />

      <Input
        id="email"
        isInvalid={formValues.email.isInvalid}
        validationMessage={formValues.email.validationMessage}
        options={{
          type: InputType.TEXT,
          label: t('contactUs.form.email.label'),
          inputProps: {
            name: 'email',
            value: formValues.email.value,
          },
        }}
        onChange={handleFormChange}
        required
      />
      <TextArea
        id="message"
        isInvalid={formValues.message.isInvalid}
        validationMessage={formValues.message.validationMessage}
        options={{
          label: t('contactUs.form.message.label'),
          textAreaProps: {
            rows: 13,
            cols: 40,
            name: 'description',
            value: formValues.message.value,
          },
        }}
        onChange={handleFormChange}
        required
      />
      <div className="ComplaintsForm__recaptcha-wrapper">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.GATSBY_WEB_TO_CASE_SALESFORCE_CAPTCHA_KEY_VALUE || ''}
          onChange={handleCaptchaOnChange}
          hl={activeLocaleTag}
          theme="dark"
        />
      </div>

      {formSubmissionResponse.shouldDisplayMessage && !formSubmissionResponse.submitted && (
        <ErrorMessage className="ComplaintsForm__generic-error-message">
          {t('contactUs.form.submissionValidation.error')}
        </ErrorMessage>
      )}

      {areFormValuesInvalid && (
        <ErrorMessage className="ComplaintsForm__generic-error-message">
          {t('contactUs.form.genericErrorMessage')}
        </ErrorMessage>
      )}

      <Button
        id="contact-submit"
        type="submit"
        iconOptions={{ icon: IconTypes.SEND_MAIL }}
        styleOptions={{ isInline: true }}
      >
        {t('contactUs.form.submitLabel')}
      </Button>
    </form>
  );
};

export default ComplaintsFormForm;
