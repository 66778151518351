import React, { useState } from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';

import { useLocaleContext } from '../../../context/Locale';
import Layout, { HeroSectionColors, LayoutOptions } from '../../../components/Layout/Layout';
import PageMenu from '../../../components/PageMenu/PageMenu';
import contactUsMenu from '../../../data/page-menus/contact-us';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';
import CommonData from '../../../data/common/common';
import SmallImage from '../../../components/Image/SmallImage/SmallImage';
import NativeSelect, {
  SelectOption,
} from '../../../components/primitives/NativeSelect/NativeSelect';
import ComplaintsForm, { FormFor } from '../../../components/Complaints/Form';
import { Accordion, AccordionItem } from '../../../components/Accordion/Accordion';
import './style.scss';

type Props = PageProps & WithTranslation;

const ComplaintsHandling: React.FC<Props> = (props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();
  const [inquiryRegarding, setInquiryRegarding] = useState(FormFor.default);

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('complaintsHandling.seo.title'),
    },
    page: {
      title: t('complaintsHandling.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-image-4.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const emails = {
    mortgageServicing: t(`${LocaleNameSpaceKeys.COMMON}:emails.complaintsServicing`),
    mortgageOriginations: t(`${LocaleNameSpaceKeys.COMMON}:emails.complaintsOriginations`),
    privacy: t(`${LocaleNameSpaceKeys.COMMON}:emails.complaintsPrivacy`),
    gic: t(`${LocaleNameSpaceKeys.COMMON}:emails.complaintsGIC`),
    complaints: t(`${LocaleNameSpaceKeys.COMMON}:emails.complaints`),
    compliance: t(`${LocaleNameSpaceKeys.COMMON}:emails.compliance`),
    obsi: t(`${LocaleNameSpaceKeys.COMMON}:emails.obsi`),
    escalated: t(`${LocaleNameSpaceKeys.COMMON}:emails.complaintsEscalated`),
  };

  const inquiryOnChange = (val: FormFor) => setInquiryRegarding(val);

  const inquiryOptionsLocalized = t('complaintsHandling.step1.complaintOptions', {
    returnObjects: true,
  });

  const inquiryOptions: SelectOption[] = [
    {
      label: t('contactUs.form.inquiryRegarding.options.pleaseSelectOption'),
      value: FormFor.default,
      disabled: true,
    },
    ...Object.keys(inquiryOptionsLocalized).map((option: any) => ({
      label: inquiryOptionsLocalized[option],
      value: option,
    })),
  ];

  const ariaLabels = {
    fax: t(`${LocaleNameSpaceKeys.COMMON}:fax`),
    phoneNumber: t(`${LocaleNameSpaceKeys.COMMON}:phoneNumber`),
    tollFree: t(`${LocaleNameSpaceKeys.COMMON}:tollFree`),
    tty: t(`${LocaleNameSpaceKeys.COMMON}:tty`),
    email: t(`${LocaleNameSpaceKeys.COMMON}:email`),
  };

  const getStepOneContent = () => {
    switch (inquiryRegarding) {
      case FormFor.mortgageExisting:
        return (
          <p>
            <Trans
              i18nKey="complaintsHandling.step1.content.mortgageExisting"
              t={t}
              values={{
                tollFreeNumber: CommonData.tollFreeCustomerServiceNumber.mortgageServicing,
                mortgageServicingEmail: emails.mortgageServicing,
                torontoPOboxAddress: t(`${LocaleNameSpaceKeys.COMMON}:poAddresses.calgary`),
                mortgageServicingDepartment: t(
                  `${LocaleNameSpaceKeys.COMMON}:mailingDepartments.toronto.mortgageServicing`
                ),
              }}
            >
              <ExternalLink
                href={`tel:${CommonData.tollFreeCustomerServiceNumber.mortgageServicing}`}
                aria-label={`${ariaLabels.tollFree} ${CommonData.tollFreeCustomerServiceNumber.mortgageServicing}`}
              />
              <ExternalLink
                href={`mailto:${emails.mortgageServicing}`}
                aria-label={`${ariaLabels.email} ${emails.mortgageServicing}`}
              />
            </Trans>
          </p>
        );
      case FormFor.mortgageNew:
        return (
          <p>
            <Trans
              i18nKey="complaintsHandling.step1.content.mortgageNew"
              t={t}
              values={{
                tollFreeNumber: CommonData.tollFreeCustomerServiceNumber.mortgageServicing,
                mortgageOriginationsEmail: emails.mortgageOriginations,
                torontoPOboxAddress: t(`${LocaleNameSpaceKeys.COMMON}:poAddresses.calgary`),
                mortgageOriginationsDepartment: t(
                  `${LocaleNameSpaceKeys.COMMON}:mailingDepartments.toronto.mortgageOriginations`
                ),
              }}
            >
              <ExternalLink
                href={`tel:${CommonData.tollFreeCustomerServiceNumber.mortgageServicing}`}
                aria-label={`${ariaLabels.tollFree} ${CommonData.tollFreeCustomerServiceNumber.mortgageServicing}`}
              />
              <ExternalLink
                href={`mailto:${emails.mortgageOriginations}`}
                aria-label={`${ariaLabels.email} ${emails.mortgageOriginations}`}
              />
            </Trans>
          </p>
        );
      case FormFor.gic:
        return (
          <>
            <p>
              <Trans
                i18nKey="complaintsHandling.step1.content.gic"
                t={t}
                values={{
                  tollFreeNumber: CommonData.tollFreeCustomerServiceNumber.depositServices,
                  gicEmail: emails.gic,
                  torontoPOboxAddress: t(`${LocaleNameSpaceKeys.COMMON}:poAddresses.toronto`),
                  gicDepartment: t(`${LocaleNameSpaceKeys.COMMON}:mailingDepartments.toronto.gic`),
                }}
              >
                <ExternalLink
                  href={`tel:${CommonData.tollFreeCustomerServiceNumber.depositServices}`}
                  aria-label={`${ariaLabels.phoneNumber} ${CommonData.tollFreeCustomerServiceNumber.depositServices}`}
                />
                <ExternalLink
                  href={`mailto: ${emails.gic}`}
                  aria-label={`${ariaLabels.email} ${emails.gic}`}
                />
              </Trans>
            </p>
          </>
        );
      case FormFor.privacy:
        return (
          <>
            <p>
              <Trans
                i18nKey="complaintsHandling.step1.content.privacy"
                t={t}
                values={{
                  privacyEmail: emails.privacy,
                  torontoPOboxAddress: t(`${LocaleNameSpaceKeys.COMMON}:poAddresses.toronto`),
                  privacyDepartment: t(
                    `${LocaleNameSpaceKeys.COMMON}:mailingDepartments.toronto.privacyOfficer`
                  ),
                }}
              >
                <ExternalLink
                  href={`tel:${CommonData.tollFreeCustomerServiceNumber.mortgageServicing}`}
                  aria-label={`${ariaLabels.tollFree} ${CommonData.tollFreeCustomerServiceNumber.mortgageServicing}`}
                />
                <ExternalLink
                  href={`mailto: ${emails.privacy}`}
                  aria-label={`${ariaLabels.email} ${emails.privacy}`}
                />
              </Trans>
            </p>
          </>
        );
      default:
        return null;
    }
  };

  const getComplaintsReport = (reportData: any[]) => {
    const numberComplaints = t('complaintsHandling.stats.headings.numberComplaints');
    const averageLength = t('complaintsHandling.stats.headings.averageLength');
    const description = t('complaintsHandling.stats.headings.description');
    const resolved = t('complaintsHandling.stats.headings.resolved');
    const closed = t('complaintsHandling.stats.headings.closed');
    const reports = t('complaintsHandling.stats.headings.reports');
    const noComplaints = t('complaintsHandling.stats.headings.noComplaints');

    return (
      <Accordion allowZeroExpanded allowMultipleExpanded>
        {reportData.map((report) => (
          <AccordionItem
            headingProps={{ id: `heading-${report.year}` }}
            key={report.year}
            buttonOptions={{ label: reports.replace('[year]', report.year) }}
          >
            <table aria-labelledby={`heading-${report.year}`}>
              <tr>
                <td rowSpan={2}>{numberComplaints.replace('[year]', report.year)}</td>
                <th>{resolved}</th>
                <th>{closed}</th>
              </tr>
              <tr>
                <td>{report.resolved}</td>
                <td>{report.closed}</td>
              </tr>
              <tr>
                <td>{averageLength}</td>
                <td>{report.avgLengthResolved}</td>
                <td>{report.avgLengthClosed}</td>
              </tr>
              <tr>
                <td>{description}</td>
                <td>
                  <Trans>{report.descResolved}</Trans>
                </td>
                <td>
                  <Trans>{report.descClosed}</Trans>
                </td>
              </tr>
            </table>
          </AccordionItem>
        ))}
      </Accordion>
    );
  };

  return (
    <Layout options={layoutOptions} className="ComplaintsHandling">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60 ComplaintsHandling__generic-gap ComplaintsHandling__extra-margin">
            <h2 className="h3">{t('complaintsHandling.introBlock.heading')}</h2>
            <p>{t('complaintsHandling.introBlock.description')}</p>
          </section>
          <aside className="column column-33">
            <PageMenu menu={contactUsMenu} />
          </aside>
        </div>
      </div>
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-100 ComplaintsHandling__generic-gap">
            <h2 className="h3">{t('complaintsHandling.howToMakeComplaintBlock.heading')}</h2>
            <p className="ComplaintsHandling__generic-gap">
              <Trans i18nKey="complaintsHandling.howToMakeComplaintBlock.description" t={t} />
            </p>
            <div className="ComplaintsHandling__generic-gap">
              <h3>{t('complaintsHandling.step1.heading')}</h3>
              <p>{t('complaintsHandling.step1.description')}</p>

              <div className="row row-wrap">
                <div className="column column-60">
                  <NativeSelect
                    id="inquiryRegarding"
                    options={{
                      label: t('contactUs.form.inquiryRegarding.label'),
                      selectProps: {
                        value: inquiryRegarding,
                        name: 'inquiryRegarding',
                      },
                    }}
                    selectOptions={inquiryOptions}
                    onChange={inquiryOnChange}
                  />
                </div>
              </div>
            </div>

            {inquiryRegarding ? (
              <>
                <div className="ComplaintsHandling__generic-gap">
                  {getStepOneContent()}
                  <p>{t('complaintsHandling.step1.descriptionTwo')}</p>
                  <p>{t('complaintsHandling.step1.footerNote')}</p>
                </div>
                <div className="row row-wrap">
                  <div className="column column-60">
                    <ComplaintsForm formFor={inquiryRegarding} t={t} />
                  </div>
                </div>
              </>
            ) : null}

            <div className="ComplaintsHandling__generic-gap">
              <h3>{t('complaintsHandling.step2.heading')}</h3>
              <p>
                <Trans
                  t={t}
                  i18nKey="complaintsHandling.step2.description"
                  values={{ emailEscalated: emails.escalated }}
                >
                  <ExternalLink
                    href={`mailto:${emails.escalated}`}
                    aria-label={`${ariaLabels.email} ${emails.escalated}`}
                  />
                </Trans>
              </p>
            </div>

            <div className="ComplaintsHandling__generic-gap">
              <h3>{t('complaintsHandling.step3.heading')}</h3>
              <Accordion allowZeroExpanded allowMultipleExpanded>
                <AccordionItem
                  buttonOptions={{ label: t('complaintsHandling.step3.contactOBSI.heading') }}
                >
                  <p>{t('complaintsHandling.step3.contactOBSI.description')}</p>
                  <p>
                    <Trans
                      i18nKey="complaintsHandling.step3.contactOBSI.contactInfo"
                      t={t}
                      values={{
                        tollFreeNumber: CommonData.tollFreeCustomerServiceNumber.obsi,
                        faxNumber: CommonData.faxNumber.obsi.tollFree,
                        ttyNumber: CommonData.ttyNumber.obsi,
                        gtaTelNumber: CommonData.telephoneNumber.obsi.gta,
                        gtaFaxNumber: CommonData.faxNumber.obsi.gta,
                        obsiEmail: emails.obsi,
                        addressName: t(`${LocaleNameSpaceKeys.COMMON}:obsi.name`),
                        address: t(`${LocaleNameSpaceKeys.COMMON}:obsi.address`),
                      }}
                    >
                      <ExternalLink
                        href={`tel:${CommonData.tollFreeCustomerServiceNumber.obsi}`}
                        aria-label={`${ariaLabels.tollFree} ${CommonData.tollFreeCustomerServiceNumber.obsi}`}
                      />
                      <ExternalLink
                        href={`tel:${CommonData.faxNumber.obsi.tollFree}`}
                        aria-label={`${ariaLabels.tollFree} ${CommonData.faxNumber.obsi.tollFree}`}
                      />
                      <ExternalLink
                        href={`tel:${CommonData.telephoneNumber.obsi.gta}`}
                        aria-label={`${ariaLabels.phoneNumber} ${CommonData.telephoneNumber.obsi.gta}`}
                      />
                      <ExternalLink
                        href={`tel:${CommonData.faxNumber.obsi.gta}`}
                        aria-label={`${ariaLabels.fax} ${CommonData.faxNumber.obsi.gta}`}
                      />
                      <ExternalLink
                        href={`mailto:${emails.obsi}`}
                        aria-label={`${ariaLabels.email} ${emails.obsi}`}
                      />
                    </Trans>
                  </p>
                  <p>
                    <Trans
                      i18nKey="complaintsHandling.step3.contactOBSI.complaintFormDownload"
                      t={t}
                      values={{
                        obsiWebsite: CommonData.websites.obsi[activeLocaleTag],
                      }}
                    >
                      <ExternalLink href={CommonData.websites.obsi[activeLocaleTag]} />
                    </Trans>
                  </p>
                </AccordionItem>
                <AccordionItem
                  buttonOptions={{ label: t('complaintsHandling.step3.contactFCAC.heading') }}
                >
                  <p>
                    <Trans i18nKey="complaintsHandling.step3.contactFCAC.description" t={t} />
                  </p>
                  <p>{t('complaintsHandling.step3.contactFCAC.note')}</p>
                  <p>
                    <Trans
                      i18nKey="complaintsHandling.step3.contactFCAC.contactInfo"
                      t={t}
                      values={{
                        tollFreeNumber:
                          CommonData.tollFreeCustomerServiceNumber.fcac[activeLocaleTag],
                        fcacWebsite: CommonData.websites.fcac[activeLocaleTag],
                        addressName: t(`${LocaleNameSpaceKeys.COMMON}:fcac.name`),
                        address: t(`${LocaleNameSpaceKeys.COMMON}:fcac.address`),
                      }}
                    >
                      <ExternalLink
                        href={`tel:${CommonData.tollFreeCustomerServiceNumber.fcac[activeLocaleTag]}`}
                        aria-label={`${ariaLabels.tollFree} ${CommonData.tollFreeCustomerServiceNumber.fcac[activeLocaleTag]}`}
                      />
                      <ExternalLink href={CommonData.websites.fcac[activeLocaleTag]} />
                    </Trans>
                  </p>
                </AccordionItem>
                <AccordionItem
                  buttonOptions={{ label: t('complaintsHandling.step3.contactOPCC.heading') }}
                >
                  <p>{t('complaintsHandling.step3.contactOPCC.description')}</p>
                  <p>
                    <Trans
                      i18nKey="complaintsHandling.step3.contactOPCC.contactInfo"
                      t={t}
                      values={{
                        tollFreeNumber: CommonData.tollFreeCustomerServiceNumber.opcc,
                        faxNumber: CommonData.faxNumber.opcc,
                        ttyNumber: CommonData.ttyNumber.opcc,
                        opccWebsite: CommonData.websites.opcc[activeLocaleTag],
                        addressName: t(`${LocaleNameSpaceKeys.COMMON}:opcc.name`),
                        address: t(`${LocaleNameSpaceKeys.COMMON}:opcc.address`),
                      }}
                    >
                      <ExternalLink
                        href={`tel:${CommonData.tollFreeCustomerServiceNumber.opcc}`}
                        aria-label={`${ariaLabels.tollFree} ${CommonData.tollFreeCustomerServiceNumber.opcc}`}
                      />
                      <ExternalLink
                        href={`tel:${CommonData.faxNumber.opcc}`}
                        aria-label={`${ariaLabels.fax} ${CommonData.faxNumber.opcc}`}
                      />
                      <ExternalLink
                        href={`tel:${CommonData.ttyNumber.opcc}`}
                        aria-label={`${ariaLabels.tty} ${CommonData.ttyNumber.opcc}`}
                      />
                      <ExternalLink href={CommonData.websites.opcc[activeLocaleTag]} />
                    </Trans>
                  </p>
                </AccordionItem>
              </Accordion>
            </div>

            <div className="ComplaintsHandling__generic-gap">
              <ExternalLink
                href={CommonData.websites.cdic[activeLocaleTag]}
                className="ComplaintsHandling__cdic-logo"
              >
                <SmallImage fileName={`cdic-logo-${activeLocaleTag}.jpg`} alt="CDIC logo" />
              </ExternalLink>
              <h4>{t('complaintsHandling.stats.title')}</h4>
              <p>{t('complaintsHandling.body')}</p>
              {getComplaintsReport(t('complaintsHandling.stats.data', { returnObjects: true }))}
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CONTACT_US)(ComplaintsHandling);
